globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"dfa1733ecb14bc3e45f14239c25dad838147c13b"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  browserProfilingIntegration,
  feedbackIntegration,
  httpClientIntegration,
  init,
  replayIntegration,
} from "@sentry/nextjs";

init({
  dsn: "https://23a62f3cbbab4492b50d46d3f4dc306c@o4505606794379264.ingest.sentry.io/4505477061607424",

  environment: process.env.NEXT_PUBLIC_APP_ENV,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate:
    process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 1.0,
  profilesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api.complicated.life/,
    /^https:\/\/api.staging.complicated.life/,
  ],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,

  replaysOnErrorSampleRate: 0.2,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.2,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    httpClientIntegration(),
    feedbackIntegration({ autoInject: false }),
    browserProfilingIntegration(),
  ],
  ignoreErrors: [
    "AbortError",
    "globalThis",
    //   https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    //   getstream token errors: https://github.com/GetStream/stream-chat-js/blob/93c4e983a825f668c8371b458badf6cc655845f1/src/connection.ts#L522
    "WS failed with code",
    /WS failed with code \d+ and reason - .*/,
  ],
});
